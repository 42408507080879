import { FaPhone, FaPhoneAlt } from "react-icons/fa";
import "./Footer.css";
function Footer() {
  return (
    <>
      <div className="footerContainer">
        <FaPhoneAlt color="#3464B7" size={20} />
        <div className="footerPhoneNumber">&emsp;07931125003</div>
      </div>
      <div className="copyrightContainer">
        {" "}
        &#169; www.whiteplumbingheating.com
      </div>
    </>
  );
}
export default Footer;
