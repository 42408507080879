import "./Review.css";
import { FaStar } from "react-icons/fa";
function Review() {
  return (
    <>
      <div className="reviewsHeading"> Reviews</div>
      <div className="reviewContainer">
        <div className="reviewBox">
          <div className="reveiwContent">
            <div className="starsContainer">
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
            </div>
            <div className="subjectRow">Amazing Service</div>
            <div className="descriptionRow">
              Alvin has been super amazing and have been taking his service for
              more than 5 years. He has always done a good job for us. Highly
              recommended.
            </div>
            <div className="reviewerName">Adam,Richmond</div>
          </div>
        </div>
        <div className="reviewBox">
          <div className="reveiwContent">
            <div className="starsContainer">
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
            </div>
            <div className="subjectRow">Super Reliable</div>
            <div className="descriptionRow">
              Super service. Very friendly and punctual. Helped with so many
              emergencies. Thanks Alvin for your help! <br />
            </div>
            <div className="reviewerName">Maggie,Watford</div>
          </div>
        </div>
        <div className="reviewBox">
          <div className="reveiwContent">
            <div className="starsContainer">
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
            </div>
            <div className="subjectRow">Quick & Reasonable</div>
            <div className="descriptionRow">
              So quick to respond in our emergency. Fixed the issue with our
              Boiler and he was very reasonable when it came to pricing.
              <br />
            </div>
            <div className="reviewerName">Rose, Clapham</div>
          </div>
        </div>
        <div className="reviewBox">
          <div className="reveiwContent">
            <div className="starsContainer">
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
              <FaStar color="yellow" />
            </div>
            <div className="subjectRow">Trustworthy</div>
            <div className="descriptionRow">
              Deserves 5 stars for all he has done for us. He has looked into
              our heating as well as helped with plumbing issues in our house
              <br />
            </div>
            <div className="reviewerName">Jackie, Hounslow</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Review;
