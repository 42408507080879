import "./Body.css";
import mainImg from "../../assets/IMG20230605123849.jpg";
import Review from "./Review";

function Body() {
  return (
    <div className="bodyContainer">
      <div className="photoContentContainerRow">
        <div className="imageContainer">
          <img src={mainImg} className="mainImage"></img>
        </div>
        <div className="mainContentContainer">
          <ul className="featuresList">
            <li className="bulletRow">24/7 Available </li>
            <li className="bulletRow">Emergency Support</li>
            <li className="bulletRow">Fully insured</li>
            <li className="bulletRow">Greater London & nearby areas</li>
            <li className="bulletRow">Best Prices</li>
            <li className="bulletRow">Friendly and Reliable</li>
            <li className="bulletRow">20+ years of Experience</li>
          </ul>
        </div>
      </div>
      <div className="servicesContainer">
        <div className="centeredContainer">
          <div className="servicesBox">
            <ul className="servicesList">
              <li className="servicesItem">
                {" "}
                &emsp;Gas Inspections & Emergencies
              </li>
              <li className="servicesItem"> &emsp;Boiler Service</li>
              <li className="servicesItem"> &emsp;New Bathroom Installation</li>
            </ul>
          </div>
          <div className="servicesBox">
            <ul className="servicesList">
              <li className="servicesItem"> &emsp;New Kitchen Installation</li>
              <li className="servicesItem">
                {" "}
                &emsp;Hot Water Cylinder Installation
              </li>
              <li className="servicesItem">
                {" "}
                &emsp;New Heating System Installation
              </li>
            </ul>
          </div>
          <div className="servicesBox">
            <ul className="servicesList">
              <li className="servicesItem"> &emsp;Under Floor Heating</li>
              <li className="servicesItem">
                {" "}
                &emsp;Heating + DWH System Conversion
              </li>
              <li className="servicesItem">
                {" "}
                &emsp;New Heating System Installation
              </li>
            </ul>
          </div>
          <div className="servicesBox">
            <ul className="servicesList">
              <li className="servicesItem"> &emsp;Minor Electricals</li>
              <li className="servicesItem"> &emsp;Power Flushing</li>
              <li className="servicesItem"> &emsp;Drainage</li>
            </ul>
          </div>
          <div className="servicesBox">
            <ul className="servicesList">
              <li className="servicesItem"> &emsp;Emergency Response</li>
              <li className="servicesItem">
                &emsp;Facility Management & Maintenance
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Review />
    </div>
  );
}
export default Body;
