import "./Header.css";
function Header() {
  return (
    <div className="headerContainer">
      <div className="headingRow">
        <div className="logoName">WHITE PLUMBING & HEATING</div>
        <div className="callNumber"> Call : 07931125003</div>
      </div>
    </div>
  );
}
export default Header;
